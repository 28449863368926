import {
  Configuration,
  DlqiResource,
  DsasResource,
  NoteResource,
  PatientResource,
  PoscoradResource,
  ProradResource,
  UserResource
} from "@nia-health/nia-backend-client-typescript-fetch";
import { User } from "oidc-react";
import { Outh2Middleware } from "./Outh2Middleware";


const backendBasePath = (window as any).ENDPOINT_ASSESSMENT_URI;

export function createDsasResource(user: User): DsasResource {
  return new DsasResource(
    new Configuration({
      basePath: backendBasePath,
      middleware: [new Outh2Middleware(async () => user.access_token)],
    })
  );
}

export function createProradResource(user: User): ProradResource {
  return new ProradResource(
    new Configuration({
      basePath: backendBasePath,
      middleware: [new Outh2Middleware(async () => user.access_token)],
    })
  );
}

export function createDlqiResource(user: User): DlqiResource {
  return new DlqiResource(
    new Configuration({
      basePath: backendBasePath,
      middleware: [new Outh2Middleware(async () => user.access_token)],
    })
  );
}

export function createPoscoradResource(user: User): PoscoradResource {
  return new PoscoradResource(
    new Configuration({
      basePath: backendBasePath,
      middleware: [new Outh2Middleware(async () => user.access_token)],
    })
  );
}

export function createNoteResource(user: User): NoteResource {
  return new NoteResource(
    new Configuration({
      basePath: backendBasePath,
      middleware: [new Outh2Middleware(async () => user.access_token)],
    })
  );
}

export function createUserResource(user: User): UserResource {
  return new UserResource(
    new Configuration({
      basePath: backendBasePath,
      middleware: [new Outh2Middleware(async () => user.access_token)],
    })
  );
}

export function createPatientResource(user: User): PatientResource {
  return new PatientResource(
    new Configuration({
      basePath: backendBasePath,
      middleware: [new Outh2Middleware(async () => user.access_token)],
    })
  );
}