import styles from "./Export.module.css";
import { useCallback, useEffect, useMemo } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import Card from "react-bootstrap/Card";
import { useAuth } from "oidc-react";
import { useImmer } from "use-immer";
import {
  createDlqiResource,
  createPoscoradResource,
  createProradResource,
} from "../client/backend/BackendClientFactory";
import { saveData } from "../download/DownloadUtil";
import { format } from "date-fns";

const DAY_FORMAT = "yyyy-MM-dd";

const SCORE_NAMES = ["DSAS", "ProRaD", "PO-SCORAD", "Flare-up", "DLQI"] as const;
type Score = (typeof SCORE_NAMES)[number];

type AvailableExport = {
  groupName: string;
  scores: Score[];
};

function useAvailableExport(): AvailableExport[] {
  const auth = useAuth();
  const userId = auth.userData?.profile.sub;
  return useMemo(() => {
    // Oliver Welter
    if (userId === "80857e8c-0b1c-4e39-9b16-afbe1e09f5a5" || userId === "635e358a-397d-4266-87cc-a3e9ae97a6f7" || userId === "bdce6822-b68b-4f72-98a7-0086695d4155") {
      return [
        {
          groupName: "PRORAD_ZUERICH",
          scores: ["ProRaD", "PO-SCORAD", "DLQI"],
        },
      ];
    }
    return [];
  }, [userId]);
}



export const Export = (): JSX.Element => {
  const availableExports = useAvailableExport();
  const { t } = useTranslation();
  const auth = useAuth();

  const [selectedExport, setSelectedExport] = useImmer<AvailableExport | null>(
    null
  );

  const [selectedScore, setSelectedScore] = useImmer<Score | null>(null);

  const options = useMemo(
    () =>
      availableExports.map((v) => (
        <option key={v.groupName} value={v.groupName}>
          {v.groupName}
        </option>
      )),
    [availableExports]
  );

  const options2 = useMemo(
    () =>
      selectedExport?.scores.map((v) => (
        <option key={v} value={v}>
          {v}
        </option>
      )),
    [selectedExport]
  );

  useEffect(() => {
    if (availableExports && availableExports.length > 0) {
      setSelectedExport(availableExports[0]);
    }
  }, [setSelectedExport, availableExports]);

  const handleSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      if (selectedExport && auth.userData) {
        const { groupName } = selectedExport;

        console.log(selectedScore);

        switch (selectedScore) {
          case "ProRaD": {
            const response = await createProradResource(
              auth.userData
            ).getProradsForGroupAsCsv({ groupName });
            saveData(
              response,
              `${format(new Date(), DAY_FORMAT)}-prorad-${groupName}.csv`
            );
            break;
          }
          case "DLQI": {
            const response = await createDlqiResource(
              auth.userData
            ).getDlqisForGroupAsCsv({ groupName });
            saveData(
              response,
              `${format(new Date(), DAY_FORMAT)}-dlqi-${groupName}.csv`
            );
            break;
          }
          case "PO-SCORAD": {
            const response = await createPoscoradResource(
              auth.userData
            ).getPoscoradsForGroupAsCsv({ groupName });
            saveData(
              response,
              `${format(new Date(), DAY_FORMAT)}-po-scorad-${groupName}.csv`
            );
            break;
          }
          default:
        }
      }
    },
    [auth.userData, selectedExport, selectedScore]
  );

  const handleGroupChange = useCallback((groupName: string) => {
    console.log(groupName);
  }, []);

  const handleScoreChange = useCallback(
    (score: string) => {
      if (SCORE_NAMES.includes(score as Score)) {
        setSelectedScore(score as Score);
      }
    },
    [setSelectedScore]
  );

  return (
    <div
      className={styles.export}
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "center",
        marginTop: "5rem",
      }}
    >
      <div style={{ width: "50%" }}>
        <Card style={{ backgroundColor: "#f3f6ff", padding: "1rem" }}>
          <Card.Text style={{ textAlign: "center" }}>
            {t("export.hint")}
          </Card.Text>
        </Card>
        <Form onSubmit={handleSubmit} style={{ marginTop: "2rem" }}>
          <Form.Group style={{ marginTop: "0.5rem" }}>
            <Form.Select onChange={(e) => handleGroupChange(e.target.value)}>
              {options}
            </Form.Select>
          </Form.Group>
          <Form.Group style={{ marginTop: "0.5rem" }}>
            <Form.Select onChange={(e) => handleScoreChange(e.target.value)}>
              {options2}
            </Form.Select>
          </Form.Group>
          <Button style={{ marginTop: "0.5rem" }} type="submit">
            {t("export.export")}
          </Button>
        </Form>
      </div>
    </div>
  );
};
