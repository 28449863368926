import styles from "./ActivationCode.module.css";
import "react-datepicker/dist/react-datepicker.css";
import { Tab, Tabs } from "react-bootstrap";
import { GenerationView } from "./GenerationView";
import { ReportView } from "./ReportView";
import { hasAnyOfRequiredRoles } from "../auth/authUtil";
import { useRoles } from "../auth/authHooks";

export const ActivationCode: React.FC = () => {
  const roles = useRoles();

  const tabs = [];
  tabs.push(
    <Tab eventKey="generation" title="Generation">
      <GenerationView />
    </Tab>
  );

  if (hasAnyOfRequiredRoles(roles, ["create-activation-partner-export"])) {
    tabs.push(
      <Tab eventKey="report" title="Report">
        <ReportView />
      </Tab>
    );
  }

  return (
    <div
      className={styles.activationCode}
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "center",
        marginTop: "5rem",
      }}
    >
      <div style={{ width: "50%" }}>
        <Tabs
          defaultActiveKey="generation"
          id="uncontrolled-tab-example"
          className="mb-3"
          style={{ marginTop: "0.5rem" }}
        >
          {tabs}
        </Tabs>
      </div>
    </div>
  );
};
