import { useTranslation } from "react-i18next";

export const ManualOverview = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "center",
        marginTop: "5rem",
      }}
    >
      <div style={{ width: "50%" }}>
        <nav>
          <ul>
            <li>
              <a
                href="https://cms.nia-health.de/manual/adcompanion/counselor/latest/de"
                target="_blank"
                rel="noreferrer"
              >
                {t("manual.adcompanionCounselor")}
              </a>
            </li>
            <li>
              <a
                href="https://cms.nia-health.de/manual/adcompanion/interventiongroup/latest/de"
                target="_blank"
                rel="noreferrer"
              >
                {t("manual.adcompanionInterventionGroup")}
              </a>
            </li>
            <li>
              <a
                href="https://cms.nia-health.de/manual/adcompanion/controlgroup/latest/de"
                target="_blank"
                rel="noreferrer"
              >
                {t("manual.adcompanionControlGroup")}
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};
