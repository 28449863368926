import { Outlet } from "react-router-dom";
import { Sidenav } from "./Sidenav";

export const Layout = (): JSX.Element => {
  return(
    <>
      <Sidenav />
      <Outlet />
    </>
  );
};
