import styles from "./Sidenav.module.css";
import { Link } from "react-router-dom";
import { useAuth } from "oidc-react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { ChangeEvent, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ClientRole } from "../auth/ClientRole";
import { useRoles } from "../auth/authHooks";
import { hasAnyOfRequiredRoles } from "../auth/authUtil";

type NavigationEntry = {
  type : "INTERNAL" | "EXTERNAL",
  label: string;
  to: string;
  anyOfRoles: ClientRole[];
};

function useNavigationEntries(): NavigationEntry[] {
  const { t } = useTranslation();

  const navigationEntries: NavigationEntry[] = useMemo(
    () => [
      {
        type: "INTERNAL",
        label: t("activationCode.activationCode_plural"),
        to: "activationcode",
        anyOfRoles: ["access-activation-code"]
      },
      {
        type: "INTERNAL",
        label: t("export.export"),
        to: "export",
        anyOfRoles: ["access-export"]
      },
      {
        type: "INTERNAL",
        label: t("note.note"),
        to: "note",
        anyOfRoles: ["access-note"]
      },
      {
        type: "INTERNAL",
        label: t("report.report"),
        to: "report",
        anyOfRoles: ["access-report"]
      },
      {
        type: "EXTERNAL",
        label: t("appointment.appointment"),
        to: "https://appointments.adcompanion.nia-health.de/index.php/user/login",
        anyOfRoles: ["access-appointment"]
      },
      {
        type: "EXTERNAL",
        label: t("chat.chat"),
        to: "https://webchat.nia-health.de",
        anyOfRoles: ["access-chat"]
      },
      {
        type: "INTERNAL",
        label: t("manual.manual_plural"),
        to: "manual",
        anyOfRoles: ["access-adcompanion-manual"]
      },
      {
        type: "INTERNAL",
        label: t("user.profile"),
        to: "profile",
        anyOfRoles: ["manage-user-profile"]
      },
      {
        type: "INTERNAL",
        label: t("dashboard.dashboard"),
        to: "dashboard",
        anyOfRoles: ["view-dashboard-prorad"]
      }
    ],
    [t]
  );

  return navigationEntries;
}

function toLink({ to, label, type } : NavigationEntry) : JSX.Element {
  if(type === "INTERNAL") {

  return(
    <li key={to}>
      <Link to={to}>{label}</Link>
    </li>
  );
  } 
  return (
    <li key={to}>
      <a href={to}  target="_blank" rel="noreferrer" >{label}</a>
    </li>
  )
}

export const Sidenav = (): JSX.Element => {
  const { t, i18n } = useTranslation();
  const auth = useAuth();
  const roles = useRoles();
  const navigationEntries = useNavigationEntries();

  const links = useMemo(() => navigationEntries.filter(e => hasAnyOfRequiredRoles(roles, e.anyOfRoles)).map(toLink),[roles, navigationEntries]);
    
  const handleLanguageSelection = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => i18n.changeLanguage(e.target.value),
    [i18n]
  );

  return (
    <div className={styles.sidenavClosed}>
      <nav>
        <ul>
          {links}
        </ul>
      </nav>
      <Form.Select value={i18n.language} onChange={handleLanguageSelection}>
        <option value="de">
          {
            // eslint-disable-next-line i18next/no-literal-string
          }
          Deutsch
        </option>
        <option value="en">
          {
            // eslint-disable-next-line i18next/no-literal-string
          }
          English
        </option>
      </Form.Select>
      <Button style={{ marginTop: "0.5rem" }} onClick={() => { 
          auth.userManager.revokeTokens();
          auth.signOutRedirect({
            id_token_hint : auth.userData?.id_token
          });
        }}>
        {t("common.logout")}
      </Button>
    </div>
  );
};
