import "react-datepicker/dist/react-datepicker.css";
import { ChangeEvent, useCallback, useEffect, useMemo } from "react";
import { useAuth } from "oidc-react";
import Button from "react-bootstrap/Button";
import { ProductResponseV2 } from "@nia-medtech/product-client-typescript-fetch";
import { useImmer } from "use-immer";
import DatePicker, { registerLocale } from "react-datepicker";
import { add, endOfDay, format, formatISO, startOfDay } from "date-fns";
import en from "date-fns/locale/en-US";
import de from "date-fns/locale/de";
import { createProductResource } from "../client/product/ProductClientFactory";
import { saveData } from "../download/DownloadUtil";
import { Card, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

registerLocale("en", en);
registerLocale("de", de);

export const GenerationView: React.FC = () => {
  const auth = useAuth();
  const { t, i18n } = useTranslation();

  const [products, setProducts] = useImmer<ProductResponseV2[]>([]);
  const [activationCodeAmount, setActivationCodeAmount] = useImmer(10);
  const [selectedProduct, setSelectedProduct] = useImmer<
    ProductResponseV2 | undefined
  >(undefined);
  const [startDate, setStartDate] = useImmer<Date>(startOfDay(new Date()));
  const [endDate, setEndDate] = useImmer<Date>(
    endOfDay(add(new Date(), { years: 1 }))
  );

  useEffect(() => {
    if (auth.userData) {
      const user = auth.userData;
      createProductResource(user)
        .getProducts()
        .then((response) => {
          if (response.embedded?.products) {
            const products = response.embedded.products.filter(
              (p) => p.partnerName === "CKCARE"
            ).filter((p) => p.groupName === "PRORAD_ZUERICH" || p.groupName === "PRORAD_AUGSBURG" || p.groupName === "PRORAD_TEST" || p.groupName === "PRORAD_DAVOS");

            if (products) {
              setProducts(products);
              setSelectedProduct(products[0]);
            }
          } else {
            setProducts([]);
            setSelectedProduct(undefined);
          }
        });
    }
  }, [auth.userData, setProducts, setSelectedProduct]);

  const options = useMemo(
    () =>
      products.map((p) => (
        <option key={p.referenceProductId} value={p.referenceProductId}>
          {p.referenceProductId}
        </option>
      )),
    [products]
  );

  const handleSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      if (selectedProduct && auth.userData) {
        const { referenceProductId } = selectedProduct;

        const response = await createProductResource(
          auth.userData
        ).generateBatchForProduct({
          referenceProductId,
          batchGenerationRequestV3: {
            startDateTime: startDate,
            expirationDateTime: endDate,
            activationCodeAmount,
            batchTag: formatISO(startDate),
          },
        });

        saveData(
          response,
          `${selectedProduct.referenceProductId}-${format(
            startDate,
            "yyyy-MM-dd"
          )}.csv`
        );
      }
    },
    [activationCodeAmount, endDate, selectedProduct, startDate, auth.userData]
  );

  const handleProductSelection = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      const product = products.find(
        (p) => p.referenceProductId === e.target.value
      );
      console.log(product);
      setSelectedProduct(product);
    },
    [products, setSelectedProduct]
  );

  return (
    <>
      <Form.Group>
        <Form.Label>{t("activationCode.product")}</Form.Label>
        <Form.Select
          value={selectedProduct?.referenceProductId}
          onChange={handleProductSelection}
        >
          {options}
        </Form.Select>
      </Form.Group>
      <Card style={{ backgroundColor: "#f3f6ff", padding: "1rem" }}>
        <Card.Text style={{ textAlign: "center" }}>
          {t("activationCode.hint")}
        </Card.Text>
      </Card>
      <Form onSubmit={handleSubmit} style={{ marginTop: "2rem" }}>
        <Form.Group style={{ marginTop: "0.5rem" }}>
          <Form.Label>{t("activationCode.prefix")}</Form.Label>
          <Form.Control
            value={selectedProduct?.defaultActivationCodePrefix}
            type="text"
            disabled
          />
        </Form.Group>
        <Form.Group style={{ marginTop: "0.5rem" }}>
          <Form.Label>{t("activationCode.suffixLength")}</Form.Label>
          <Form.Control
            value={selectedProduct?.defaultActivationCodeSuffixLength}
            type="number"
            disabled
          />
        </Form.Group>
        <Form.Group style={{ marginTop: "0.5rem" }}>
          <Form.Label>{t("activationCode.startDate")}</Form.Label>
          <DatePicker
            locale={i18n.language}
            dateFormat={t("common.dateFormatString") as string}
            selected={startDate}
            onChange={(v) => (v ? setStartDate(v) : null)}
          />
        </Form.Group>
        <Form.Group style={{ marginTop: "0.5rem" }}>
          <Form.Label>{t("activationCode.expirationDate")}</Form.Label>
          <DatePicker
            locale={i18n.language}
            dateFormat={t("common.dateFormatString") as string}
            selected={endDate}
            onChange={(v) => (v ? setEndDate(v) : null)}
          />
        </Form.Group>
        <Form.Group style={{ marginTop: "0.5rem" }}>
          <Form.Label>{t("activationCode.amount")}</Form.Label>
          <Form.Control
            defaultValue={activationCodeAmount}
            type="number"
            onChange={(e) => setActivationCodeAmount(Number(e.target.value))}
          />
        </Form.Group>
        <Button style={{ marginTop: "0.5rem" }} type="submit">
          {t("common.generate")}
        </Button>
      </Form>
    </>
  );
};
