import styles from "./Report.module.css";
import { ChangeEvent, useCallback, useEffect, useMemo } from "react";

export const Report = (): JSX.Element => {
  return (
    <div
      className={styles.report}
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "center",
        marginTop: "5rem",
      }}
    ></div>
  );
};
