import { PatientResponseV1 } from "@nia-health/nia-backend-client-typescript-fetch";
import { useCallback, useMemo } from "react";
import { Form } from "react-bootstrap";

type Props = {
    value: PatientResponseV1 | undefined;
    options: PatientResponseV1[];
    onChange: (value: PatientResponseV1 | undefined) => void;
  };
  
  export const SelectRoom: React.FC<Props> = ({
    value,
    options,
    onChange,
  }) => {
    const renderedOptions: JSX.Element[] = useMemo(
      () =>
        options.map((o) => (
          <option key={o.patientId} value={o.patientId}>
            {`${o.fullname} (${o.patientId})`}
          </option>
        )),
      [options]
    );
  
    const onInternalChange = useCallback(
      (patientId: string | undefined) => {
        onChange(options.findLast((o) => o.patientId === patientId));
      },
      [onChange, options]
    );
  
    return (
      <Form.Select
        value={value?.patientId}
        onChange={(e) => onInternalChange(e.target.value)}
      >
        {renderedOptions}
      </Form.Select>
    );
  };
  