import { useAuth } from "oidc-react";
import { ClientRole } from "./ClientRole";
import jwt_decode from "jwt-decode";

type ResourceClaim = {
    roles : ClientRole[]
};

type JwtAccessTokenPayload = {
  resource_access: Record<string, ResourceClaim>;
};

export function useRoles(): ClientRole[] {
  const auth = useAuth();
  if (auth.userData?.access_token) {
    const token = jwt_decode<JwtAccessTokenPayload>(auth.userData?.access_token);
    if(token.resource_access[auth.userManager.settings.client_id]?.roles) {
      return token.resource_access[auth.userManager.settings.client_id]?.roles;
    }
  }
  return [];
}
