export function saveData(payload: BlobPart, filename: string): void {
  const element = document.createElement("a");
  const file = new Blob([payload], {
    type: "text/plain",
  });
  element.href = URL.createObjectURL(file);
  element.download = filename;
  document.body.appendChild(element);
  element.click();
}
