import { FetchParams, Middleware, RequestContext } from "@nia-medtech/product-client-typescript-fetch";

export class Outh2Middleware implements Middleware {
    constructor(
      private readonly accessTokenAsync: () => Promise<string | undefined>
    ) {}
  
    async pre(context: RequestContext): Promise<FetchParams | void> {
      const { url, init } = context;
  
      const accessToken = await this.accessTokenAsync();
  
      let enhancedInit = init;
      if (accessToken) {
        enhancedInit = {
          ...init,
  
          headers: {
            ...init?.headers,
            Authorization: `Bearer ${accessToken}`,
          },
        };
      }
  
      return {
        url,
        init: enhancedInit,
      };
    }
  }