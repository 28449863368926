import { NoteResponseV1 } from "@nia-health/nia-backend-client-typescript-fetch";
import { useCallback, useMemo } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

type Props = {
    disabled: boolean | undefined,
    value: NoteResponseV1 | undefined;
    options: NoteResponseV1[];
    onChange: (value: NoteResponseV1 | undefined) => void;
  };
  
  export const SelectNote: React.FC<Props> = ({
    disabled,
    value,
    options,
    onChange,
  }) => {

    const {t} = useTranslation();

    const renderedOptions: JSX.Element[] = useMemo(
      () => {
        const renderedOptions : JSX.Element[] = [
          <option key={"none"} value={undefined}>{`<${t("note.new")}>`}</option>
        ]
      
        options.forEach((o) => renderedOptions.push(
          <option key={o.noteId} value={o.noteId}>
            {
              t("note.dateTime", {val : o.authored, formatParams: { val : {dateStyle : "short", timeStyle : "short"}}})
            }
          </option>
        ));

        return renderedOptions;
      },
      [options, t]
    );
  
    const onInternalChange = useCallback(
      (noteId: string | undefined) => {
        onChange(options.findLast((o) => o.noteId === noteId));
      },
      [onChange, options]
    );
  
    return (
      <Form.Select
        disabled={disabled}
        value={value?.noteId}
        onChange={(e) => onInternalChange(e.target.value)}
      >
        {renderedOptions}
      </Form.Select>
    );
  };
  