import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ActivationCode } from "./activationcode/ActivationCode";
import { Export } from "./export/Export";
import { Layout } from "./navigation/Layout";
import { ErrorPage } from "./navigation/ErrorPage";
import { AuthProvider } from "oidc-react";
import { Report } from "./report/Report";
import { Note } from "./note/Note";
import { ManualOverview } from "./manual/ManualOverview";
import { UserProfile } from "./user/UserProfile";
import { Dashboard } from "./dashboard/Dashboard";

const router = createBrowserRouter([
  {
    path: "/",
    Component: Layout,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/activationcode",
        Component: ActivationCode,
      },
      {
        path: "/export",
        Component: Export,
      },
      {
        path: "/note",
        Component: Note,
      },
      {
        path: "/report",
        Component: Report,
      },
      {
        path: "/manual",
        Component: ManualOverview
      },
      {
        path: "/profile",
        Component: UserProfile
      },
      {
        path: "/dashboard",
        Component: Dashboard
      }
    ]
  },
]);

const OAUTH_AUTHORITY_URI = (window as any).OAUTH_AUTHORITY_URI;
const OAUTH_CLIENT_ID = (window as any).OAUTH_CLIENT_ID;
const OAUTH_REDIRECT_URI = (window as any).OAUTH_REDIRECT_URI;

export const App = (): JSX.Element => {
  return (
    <AuthProvider
      authority={OAUTH_AUTHORITY_URI}
      clientId={OAUTH_CLIENT_ID}
      redirectUri={OAUTH_REDIRECT_URI}
      scope="openid profile roles"
    >
      <RouterProvider router={router} />
    </AuthProvider>
  );
};
