import { useEffect, useState } from "react";
import { generateMetabaseUrl } from "./utils/Metabase";

interface IframeProps{
  url: string;
}

interface ErrorProps{
  message: string;
}

const IframeComponent = ({ url }: IframeProps): JSX.Element => {
  return (
    <iframe
      title="dashboard"
      src={url}
      frameBorder="0"
      width="100%"
      height="100%"
      style={{ width: '100%', height: '100%' }}
    ></iframe>
  );
};

const ErrorComponent = ({ message }: ErrorProps): JSX.Element => {
  return <p>{message}</p>;
};


export const Dashboard = (): JSX.Element => {
  const [iframeUrl, setIframeUrl] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const setupIframeUrlAsync = async () => {
      try {
        const ENDPOINT_METABASE_SITE_URI = (window as any).ENDPOINT_METABASE_SITE_URI as string;
        const METABASE_SECRET_KEY = (window as any).METABASE_SECRET_KEY as string;
  
        if (!ENDPOINT_METABASE_SITE_URI || !METABASE_SECRET_KEY) {
          throw new Error('Missing Metabase configuration parameters.');
        }
        const url = await generateMetabaseUrl(ENDPOINT_METABASE_SITE_URI, METABASE_SECRET_KEY);
        setIframeUrl(url);
      } catch (error) {
        setError((error as Error).message);
      }
    };
    setupIframeUrlAsync();
  }, []);

  return (
    <div style={{ width: '100%', height: '100vh', margin: 0, padding: 0 }}>
      {error && <ErrorComponent message={error} />}
      {!error && iframeUrl && <IframeComponent url={iframeUrl} />}
    </div>
  );
}; 