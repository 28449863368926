import Button from "react-bootstrap/Button";
import styles from "./UserProfile.module.css";
import { useTranslation } from "react-i18next";
import { useAuth } from "oidc-react";
import { createUserResource } from "../client/user/UserClientFactory";
import { getUserId } from "./UserUtil";

export const UserProfile: React.FC = () => {
  const auth = useAuth();
  const { t } = useTranslation();
  return (
    <div
      className={styles.userProfile}
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "center",
        marginTop: "5rem",
      }}
    >
      <div>
        <Button style={{ backgroundColor: "red" }} onClick={() => {
          if(auth.userData) {
            const userResource = createUserResource(auth.userData);
            userResource.deleteUser({userId : getUserId(auth.userData)}).then(() => {
              auth.signOutRedirect({
                id_token_hint : auth.userData?.id_token
              });
            });
          }
        }}>
          {t("user.deleteAccount")}
        </Button>
      </div>
    </div>
  );
};
