import { ChangeEvent, useCallback, useEffect, useMemo } from "react";
import { User, useAuth } from "oidc-react";
import Button from "react-bootstrap/Button";
import { PartnerResponseV3 } from "@nia-medtech/product-client-typescript-fetch";
import { useImmer } from "use-immer";
import { createPartnerResource } from "../client/product/ProductClientFactory";
import { saveData } from "../download/DownloadUtil";
import { Card, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";

async function loadPartnersAsync(user: User): Promise<PartnerResponseV3[]> {
  const response = await createPartnerResource(user).getPartners();
  if (response.embedded?.partners) {
    return response.embedded.partners.sort((a, b) => a.name.localeCompare(b.name));
  }
  return [];
}

export const ReportView: React.FC = () => {
  const auth = useAuth();
  const { t } = useTranslation();

  const [partners, setPartners] = useImmer<PartnerResponseV3[]>([]);
  const [selectedPartner, setSelectedPartner] = useImmer<
    PartnerResponseV3 | undefined
  >(undefined);

  const user = auth.userData;

  useEffect(() => {
    if (user) {
      loadPartnersAsync(user).then((partners) => {
        setPartners(partners);
      });
    }
  }, [setPartners, user]);

  const options = useMemo(
    () =>
      partners.map((p) => (
        <option key={p.name} value={p.name}>
          {p.name}
        </option>
      )),
    [partners]
  );

  const handlePartnerSelection = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      const partner = partners.find((p) => p.name === e.target.value);
      setSelectedPartner(partner);
    },
    [partners, setSelectedPartner]
  );

  const downloadReportAsync = useCallback(
    async (user: User, partner: PartnerResponseV3) => {
      const response = await createPartnerResource(user).reportReedemedVouchers(
        { partnerName: partner.name }
      );
      saveData(
        response,
        `${partner.name}-${format(new Date(), "yyyy-MM-dd")}.csv`
      );
    },
    []
  );

  return (
    <>
      <Card style={{ backgroundColor: "#f3f6ff", padding: "1rem" }}>
        <Card.Text style={{ textAlign: "center" }}>
          {t("activationCode.hint")}
        </Card.Text>
      </Card>
      <Form.Group>
        <Form.Label>{t("activationCode.partner")}</Form.Label>
        <Form.Select
          value={selectedPartner?.name}
          onChange={handlePartnerSelection}
        >
          {options}
        </Form.Select>
      </Form.Group>
      <Button
        style={{ marginTop: "0.5rem" }}
        type="submit"
        onClick={(e) => {
          e.preventDefault();
          if (user && selectedPartner) {
            downloadReportAsync(user, selectedPartner).catch(console.error);
          }
        }}
      >
        {t("common.generate")}
      </Button>
    </>
  );
};
