import { SignJWT } from 'jose';

export const generateMetabaseUrl = async (siteUri:string, secretKey:string) => {
  try {
    const payload = {
      resource: { dashboard: 52 },
      params: {},
      exp: Math.round(Date.now() / 1000) + (10 * 60) // 10 minute expiration
    };

    const secretKeyEncoded = new TextEncoder().encode(secretKey);

    const token = await new SignJWT(payload)
      .setProtectedHeader({ alg: 'HS256' })
      .setExpirationTime('10m')
      .sign(secretKeyEncoded);

    return `${siteUri}/embed/dashboard/${token}#bordered=true&titled=true`;
  } catch (error) {
    console.error("Error generating Metabase URL:", error);
    throw new Error('Failed to generate Metabase URL.');
  }
};