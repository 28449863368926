import { ClientRole } from "./ClientRole";

export type FetchApi = (
  input: RequestInfo,
  init?: RequestInit | undefined
) => Promise<Response>;

/**
 * Create fetch api with automatic bearer token extension.
 * Uses given token response or retrieves one.
 *
 * @param tokenResponse - reponse containing token received from ID Provider
 * @returns fetch api
 */
export function createFetch(accessToken: string): FetchApi {
  return (input: RequestInfo, init?: RequestInit): Promise<Response> => {
    const enhancedInit : RequestInit = {
      ...init,
      headers: {
        ...init?.headers,
        Authorization: `Bearer ${accessToken}`,
      },
      mode : "no-cors"
    };
    return fetch(input, enhancedInit);
  };
}

export function hasAnyOfRequiredRoles(currentRoles : ClientRole[], anyOfRequiredRoles : ClientRole[]) : boolean {
  for(const role of currentRoles) {
    if(anyOfRequiredRoles.includes(role)) {
      return true;
    }
  }  
  return false;
}

