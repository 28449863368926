import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import de from "./locales/de.json";
import en from "./locales/en.json";

const resources = {
  en: {
    translation: en,
  },
  de: {
    translation: de,
  },
};

i18next.use(initReactI18next).use(LanguageDetector).init({
    resources,
    lng: "de"
});

export default i18next;
