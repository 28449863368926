import {
  Configuration,
  PartnerResource,
  ProductResource,
} from "@nia-medtech/product-client-typescript-fetch";
import { User } from "oidc-react";
import { Outh2Middleware } from "./Outh2Middleware";

const productBasePath = (window as any).ENDPOINT_PRODUCT_URI;

export function createPartnerResource(user: User): PartnerResource {
  return new PartnerResource(
    new Configuration({
      basePath: productBasePath,
      middleware: [new Outh2Middleware(async () => user.access_token)],
    })
  );
}

export function createProductResource(user: User): ProductResource {
  return new ProductResource(
    new Configuration({
      basePath: productBasePath,
      middleware: [new Outh2Middleware(async () => user.access_token)],
    })
  );
}
