import styles from "./Note.module.css";
import { User, useAuth } from "oidc-react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import { useCallback, useEffect } from "react";
import {
  NoteCreationForUserRequestV1,
  NoteResponseV1,
  NoteUpdateRequestV1,
  PatientResponseV1,
} from "@nia-health/nia-backend-client-typescript-fetch";
import { useImmer } from "use-immer";
import { useTranslation } from "react-i18next";
import { InputGroup } from "react-bootstrap";
import {
  createNoteResource,
  createUserResource as createBackendUserResource,
  createPatientResource,
} from "../client/backend/BackendClientFactory";
import { SelectRoom } from "./component/SelectRoom";
import { SelectNote } from "./component/SelectNote";

export const Note = (): JSX.Element => {
  const { t } = useTranslation();
  const auth = useAuth();

  const [patients, setPatients] = useImmer<PatientResponseV1[]>([]);
  const [selectedPatient, setSelectedPatient] = useImmer<
    PatientResponseV1 | undefined
  >(undefined);

  const [notes, setNotes] = useImmer<NoteResponseV1[]>([]);
  const [selectedNote, setSelectedNote] = useImmer<NoteResponseV1 | undefined>(
    undefined
  );

  const [noteText, setNoteText] = useImmer<string>("");

  const updateUserList = useCallback(
    async (patients: PatientResponseV1[] | undefined) => {
      if (patients === undefined) {
        setPatients([]);
        setSelectedPatient(undefined);
      } else {
        setPatients(patients);
        setSelectedPatient(patients[0]);
      }
    },
    [setPatients, setSelectedPatient]
  );

  const fetchPatientsAsync = useCallback(async (user: User) => {
    const response = await createPatientResource(user).getPatients();
    updateUserList(response.embedded?.patients);
  },[updateUserList]);


  const fetchNotesAsync = useCallback(async (user: User, subjectId: string, noteId? : string) => {
    const response = await createNoteResource(user).getNotes({ subjectId });
    if (response.embedded?.notes && response.embedded?.notes.length > 0) {
      const {notes} = response.embedded;
      setNotes(notes);
      let index = -1;
      if(noteId) {
        index = notes.findLastIndex(n => n.noteId === noteId);
      }

      setSelectedNote(notes[index === -1 ? notes.length - 1: index]);
    } else {
      setNotes([]);
      setSelectedNote(undefined);
    }
  },[setNotes, setSelectedNote]);

  const deleteCurrentNoteAsync = useCallback(async () => {
    if (auth.userData && selectedNote && selectedPatient) {
      const {noteId} = selectedNote;
      await createNoteResource(auth.userData).deleteNote({noteId});
      await fetchNotesAsync(auth.userData, selectedPatient.patientId);
    }
  },[auth.userData, fetchNotesAsync, selectedNote, selectedPatient]);

  const handleSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      if (auth.userData && selectedPatient) {
        const userId = auth.userData?.profile.sub;
        if (selectedNote === undefined) {
          const noteCreationForUserRequestV1: NoteCreationForUserRequestV1 = {
            authored: new Date(),
            subjectId: selectedPatient.patientId,
            text: noteText,
          };
          await createBackendUserResource(auth.userData)
            .createNote({ noteCreationForUserRequestV1, userId })
            .catch(console.error);
          await fetchNotesAsync(auth.userData, selectedPatient.patientId).catch(console.error);
        } else {
          const {subjectId, authorId} = selectedNote
          const noteUpdateRequestV1 : NoteUpdateRequestV1 = {
            subjectId,
            authorId,
            text: noteText,
            authored : new Date()
          }
          await createNoteResource(auth.userData).createOrUpdateNote({noteUpdateRequestV1, noteId : selectedNote.noteId}).catch(console.error);
          await fetchNotesAsync(auth.userData, selectedPatient.patientId, selectedNote.noteId).catch(console.error);
        }
        
      }
    },
    [auth.userData, selectedPatient, selectedNote, noteText, fetchNotesAsync]
  );

  useEffect(() => {
    if(selectedNote) {
      setNoteText(selectedNote.text);
    } else {
      setNoteText("")
    }
  },[selectedNote, setNoteText])

  useEffect(() => {
    if (auth.userData) {
      fetchPatientsAsync(auth.userData).catch(console.error);
    }
  }, [auth.userData, fetchPatientsAsync]);

  useEffect(() => {
    if (auth.userData && selectedPatient) {
      fetchNotesAsync(auth.userData, selectedPatient.patientId).catch(console.error);
    } else {
      setNotes([]);
      setSelectedNote(undefined);
    }
  }, [auth.userData, selectedPatient, setNoteText, updateUserList, setNotes, setSelectedNote, fetchNotesAsync]);

  return (
    <div
      className={styles.note}
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "center",
        marginTop: "5rem",
      }}
    >
      <div style={{ width: "50%" }}>
        <Card style={{ backgroundColor: "#f3f6ff", padding: "1rem" }}>
          <Card.Text style={{ textAlign: "center" }}>
            {t("note.hint")}
          </Card.Text>
        </Card>
        <Form onSubmit={handleSubmit} style={{ marginTop: "2rem" }}>
          <InputGroup style={{ marginTop: "0.5rem" }}>
            <InputGroup.Text>{t("note.patient")}</InputGroup.Text>
            <SelectRoom
              value={selectedPatient}
              options={patients}
              onChange={setSelectedPatient}
            />
          </InputGroup>
          <InputGroup style={{ marginTop: "0.5rem" }}>
            <InputGroup.Text>{t("note.note")}</InputGroup.Text>
            <SelectNote
              disabled={selectedPatient === undefined}
              value={selectedNote}
              options={notes}
              onChange={setSelectedNote}
            />
          </InputGroup>
          <InputGroup style={{ marginTop: "2rem" }}>
            <Form.Control
              disabled={selectedPatient === undefined}
              style={{ height: "30rem" }}
              as="textarea"
              aria-label="note"
              size="lg"
              value={noteText}
              onChange={(e) => setNoteText(e.target.value)}
            />
          </InputGroup>
          <InputGroup style={{ marginTop: "0.5rem", justifyContent : "space-between" }}>
            <Button disabled={noteText === "" || noteText === selectedNote?.text} style={{ marginTop: "0.5rem" }} type="submit">
              {selectedNote ? t("common.update") : t("common.create")}
            </Button>
            <Button hidden={selectedNote === undefined} style={{ marginTop: "0.5rem", backgroundColor: "red", borderColor : "red"}} type="button" onClick={() => deleteCurrentNoteAsync().catch(console.error)}>
              {t("common.delete")}
              </Button>
          </InputGroup>
        </Form>
      </div>
    </div>
  );
};
