import {
  Configuration,
  UserResource,
} from "@nia-medtech/user-client-typescript-fetch";
import { User } from "oidc-react";
import { Outh2Middleware } from "./Outh2Middleware";

const userBasePath = (window as any).ENDPOINT_USER_URI;

export function createUserResource(user: User): UserResource {
  return new UserResource(
    new Configuration({
      basePath: userBasePath,
      middleware: [new Outh2Middleware(async () => user.access_token)],
    })
  );
}
